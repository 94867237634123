.custom__card {
  padding: 0;
  border: none;
  width: 410px;
}

.custom__card div img {
  border-bottom-left-radius: 0px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 0;
  height: 280px;
  width: 100%;
  transition: transform 0.3s;
}

.custom__card div img:hover {
  transform: scale(1.29);
}

@media screen and (max-width: 765px) {
  .custom__card {
    margin-left: 1px;
    width: 370px;
  }
}
