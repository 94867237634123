.section1-container {
  background-color: rgba(66, 114, 6, 0.823);
  height: 80vh;
  margin-top: 110px;
}

.carousel__images {
  height: 80vh;
  width: 100%;
  filter: brightness(50%);
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  background-color: rgb(82, 165, 14);
  border-radius: 50%;
  background-size: 60% 60%;
  width: 70px;
  height: 70px;
  opacity: 4;
  transition: all 0.5s;
}

@media screen and (max-width: 765px) {
  .carousel__images {
    height: 50vh;
    width: 100%;
  }

  .section1-container {
    background-color: rgba(66, 114, 6, 0.823);
    height: 50vh;
    margin-top: 90px;
  }

  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    width: 50px;
    height: 50px;
    opacity: 4;
    transition: all 0.5s;
  }
}
