/* ********************************************** */
/* language */
/* ********************************************** */

#deroule {
  position: fixed;
  bottom: 0;
  z-index: 18;
  left: 36px;
  cursor: pointer;
  text-decoration: none;
  font-size: 0.7em;
}

#deroule #menu__drop {
  width: 100px;
  padding: 10px;
  background-color: white;
  border: 5px, 0;
  display: flex;
  padding-left: 10px;
  box-shadow: rgba(57, 57, 215, 0.2) 1px 1px 1px 1px;
}

#deroule #menu__drop span {
  color: green;
}

#drop {
  position: absolute;
  list-style: none;
  width: 100px;
  height: 100px;
  border-radius: 5px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  transform: translateY(-140px);
  background-color: green;
  border: 1px solid white;
  display: none;
  transition: all 1s ease;
  box-shadow: rgba(57, 57, 215, 0.2) 1px 1px 1px 1px;
}

#drop li {
  width: 100%;
  height: 50%;
  color: green;
  display: flex;
  align-items: center;
  padding-left: 10px;
  cursor: pointer;
  font-size: 0.8em;
  border-bottom: 1px solid white;
}

#drop li:hover {
  background-color: white;
  cursor: pointer;
  color: green;
}

#drop li span:hover {
  color: green;
}

#deroule:hover #drop {
  display: block;
}

#deroule span {
  color: white;
  font-weight: 500;
  display: flex;
  gap: 5px;
}

#deroule .langue {
  margin-top: 8px;
}

#deroule span img {
  margin-top: 3px;
}

@media (max-width: 960px) {
}

@media (max-width: 720px) {
  #deroule #menu__drop {
    width: 100px;
    padding: 7px;
    font-size: 0.7em;
  }
}

@media (max-width: 520px) {
  #deroule #menu__drop {
    width: 100px;
    padding: 10px;
  }
}
