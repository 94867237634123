.text-first {
  color: rgb(50, 60, 50);
  font-weight: 400;
}

.text-first:hover {
  color: rgb(62, 57, 57);
}

.nav-item .nav-link {
  font-size: 50px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.nav-item .nav-link:hover:after {
  content: "";
  position: fixed;
  top: 88px;
  width: 40px;
  height: 2px;
  background-color: green;
  animation: increase 0.3s ease-in-out;
}

@keyframes increase {
  0% {
    width: 0px;
  }
  25% {
    width: 10px;
  }
  50% {
    width: 20px;
  }
  75% {
    width: 30px;
  }
  100% {
    width: 40px;
  }
}

@media screen and (max-width: 765px) {
  .nav-item .nav-link:hover:after {
    display: none;
  }

  .custom__logo__title {
    display: none;
  }

  .custom__header {
    display: none;
  }

  .custom__navbar {
    top: -22px;
    margin-top: -40px;
  }
}
