.program__card {
  text-align: center;
  transition: all 0.5s ease;
  padding: 15px;
  min-height: 200px;
}

.program__card:hover {
  border-radius: 30px;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
  transform: scale(0.9);
  cursor: pointer;
}

.program__card .program__image {
  text-align: center;
  border-radius: 50%;
  width: 120px;
  transform: scale(0.9);
}

.program__card .program__text {
  font-size: 17px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: rgb(86, 83, 83);
}
