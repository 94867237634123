@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;400;500;600;700;800;900&display=swap");

* {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
}

body {
  width: 100%;
  height: 100%;
  font-family: "Poppins", sans-serif;
  box-sizing: border-box;
}

.custom-title {
  font-size: 32px;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
  text-transform: capitalize;
}

.bg-primary {
  color: green;
}

.text-primary {
  color: green;
}

.custom__input {
  border: none;
  outline: none !important;
  padding: none !important;
  background-color: #f3f4f6;
  border-color: transparent !important;
}

.custom__input:focus {
  border: none !important;
  outline: none !important;
  background-color: #f3f4f6;
  border-color: transparent !important;
}

@media only screen and (max-width: 768px) {
  .animated {
    -o-transition-property: none !important;
    -moz-transition-property: none !important;
    -ms-transition-property: none !important;
    -webkit-transition-property: none !important;
    transition-property: none !important;
    -o-transform: none !important;
    -moz-transform: none !important;
    -ms-transform: none !important;
    -webkit-transform: none !important;
    transform: none !important;
    -webkit-animation: none !important;
    -moz-animation: none !important;
    -o-animation: none !important;
    -ms-animation: none !important;
    animation: none !important;
  }
}
